import React from "react";
import Section from "components/Section/Section";
import Hero from "components/Hero/Hero";
import SEO from "components/seo";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Haimer = ({ data }) => {
  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black">
      </Hero>
      <SectionsWrappper>
      <Section data={data.measuring} reverse />
      <Section data={data.toolholder} />
      <Section data={data.shrinking} reverse />
      <Section data={data.presetting} />
      <Section data={data.balancing} reverse />
      <Section data={data.grinding} />
      <Section data={data.tooling} reverse />
      </SectionsWrappper>

    </>
  );
};

export default Haimer;
