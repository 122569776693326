import { useHaimerPhotos } from "shared/Hooks/haimer/useHaimerPhotos";

export const useEngHaimerData = () => {
  const data = useHaimerPhotos();
  return {
    metaInfo: {
      title: "Haimer",
      ogDescription:
        "European market leader in toolholding tech. They provide solutions of the highest quality, which are characterized by innovation supported by extensive experience. Haimer products will increase the efficiency, safety and economics of your business.",
      ogImage: data.logo.childImageSharp.fluid.src,
    },
    hero: {
      title: " Haimer - highest quality",
      subtitle:
        "European market leader in toolholding tech. They provide solutions of the highest quality, which are characterized by innovation supported by extensive experience. Haimer products will increase the efficiency, safety and economics of your business.",
      bg: data.hero,
    },
    measuring: {
      title: "3D sensors",
      subtitle: "Fast and precise positioning and measuring of the material.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-measuring.pdf",
        },
      ],
      bigImg: true,
      src: data.img1,
    },
    toolholder: {
      title: "Tool holders",
      subtitle:
        "Grips tailored to the needs of any industry: whether it requires more flexibility, minimal vibration or high cutting force.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-toolholders.pdf",
        },
      ],
      bigImg: true,
      src: data.img2,
    },
    shrinking: {
      title: "Fixing techniques",
      subtitle:
        "A device that allows you to change a tool in an instant while holding it precisely and firmly.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-shrinking.pdf",
        },
      ],
      bigImg: true,
      src: data.img3,
    },
    presetting: {
      title: "Tool measurement technologies",
      subtitle:
        "Direct data transfer to the machine increases the security of the tool programming process and increases productivity.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-presetting.pdf",
        },
      ],
      bigImg: true,
      src: data.img4,
    },
    balancing: {
      title: "Balancing technologies",
      subtitle:
        "Greater productivity, longer tool life, greater safety - thanks to greater balance in the production process.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-balancing.pdf",
        },
      ],
      bigImg: true,
      src: data.img5,
    },
    grinding: {
      title: "Adapters for grinding machines",
      subtitle:
        "Adapters to increase grinding performance and prevent productivity loss.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-grinding.pdf",
        },
      ],
      bigImg: true,
      src: data.img6,
    },
    tooling: {
      title: "Tools",
      subtitle: "Superior tool technology for maximum precision.",
      links: [
        {
          text: "Download catalogue",
          url: "/catalogs/haimer/haimer-tooltechnology.pdf",
        },
      ],
      bigImg: true,
      src: data.img7,
    },
  };
};
