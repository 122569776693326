import { graphql, useStaticQuery } from "gatsby";

export const useHaimerPhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "image/navbar/menu-foto-haimer.png" }) {
        childImageSharp {
          fluid(maxHeight: 350, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hero: file(relativePath: { eq: "image/haimer/haimer_hero.png" }) {
        childImageSharp {
          fluid(maxHeight: 1080, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "image/haimer/haimer-3dsensors.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "image/haimer/haimer-holder.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img3: file(relativePath: { eq: "image/haimer/haimer-fixing.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img4: file(relativePath: { eq: "image/haimer/haimer-measure.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img5: file(relativePath: { eq: "image/haimer/haimer-balancing.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img6: file(relativePath: { eq: "image/haimer/haimer-grinding.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img7: file(relativePath: { eq: "image/haimer/haimer-tools.png" }) {
        childImageSharp {
          fluid(maxHeight: 650, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
